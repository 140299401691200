import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { CartService } from './pages/cart-page/cart.service';
import { IS_SERVER } from './providers/is-platform.provider';
import { SpreeService } from './services/spree-client/storefront/spree.service';
import { StateService } from './services/state.service';
import { storeSerializer } from './services/store-serializer';
import { map } from 'rxjs';
import { LocalStorageService } from './services/local-storage.service';
import { LanguagePopupService } from './components/language-popup/language-popup.service';

declare const Cookiebot: { consent: { method: string | null } };

const COOKIEBOT_ACCEPT_EVENT = 'CookiebotOnAccept';
const COOKIEBOT_DECLINE_EVENT = 'CookiebotOnDecline';

const luigisBoxUrls = {
  en: 'https://scripts.luigisbox.com/LBX-576475.js',
  sk: 'https://scripts.luigisbox.com/LBX-577888.js',
  cs: 'https://scripts.luigisbox.com/LBX-578586.js',
} as Record<string, string>;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  template: ` <router-outlet></router-outlet>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  private readonly isServer = inject(IS_SERVER);
  private readonly cart = inject(CartService);
  private readonly client = inject(SpreeService);
  private readonly state = inject(StateService);
  private readonly localStorage = inject(LocalStorageService);
  private readonly router = inject(Router);
  private readonly document = inject(DOCUMENT);
  private readonly languagePopupService = inject(LanguagePopupService);
  private readonly cookiebotListenersController = new AbortController();

  // todo refactor
  ngOnInit() {
    this.state.activeLocale$.subscribe((locale) => {
      const cookiebotScript = this.document.getElementById('Cookiebot');
      const luigisBoxScript = this.document.getElementById('LuigisBox');
      this.document.documentElement.lang = locale;
      cookiebotScript!.setAttribute('data-culture', locale);
      luigisBoxScript!.setAttribute('src', luigisBoxUrls[locale] ?? luigisBoxUrls['en']);
    });

    if (this.isServer) {
      return;
    }

    if (!Cookiebot || !Cookiebot.consent) {
      return;
    }

    if (!Cookiebot.consent.method) {
      window.addEventListener(COOKIEBOT_ACCEPT_EVENT, this.openPopup, {
        passive: true,
        signal: this.cookiebotListenersController.signal,
      });
      window.addEventListener(COOKIEBOT_DECLINE_EVENT, this.openPopup, {
        passive: true,
        signal: this.cookiebotListenersController.signal,
      });
    }

    this.cart.getCart();
    this.state.activeLocale$.subscribe(() => this.updateStore());
    this.handleChunkLoadingError();
  }

  private openPopup = (): void => {
    this.languagePopupService.openPopup();
    this.cookiebotListenersController.abort();
  };

  private updateStore(): void {
    this.client.store
      .show()
      .pipe(map((storeDto) => storeSerializer(storeDto)))
      .subscribe((store) => {
        this.state.setStore(store);
      });
  }

  private handleChunkLoadingError(): void {
    const recoverUrl = this.localStorage.getItem('chunkLoadErrorReloadTarget');

    if (!recoverUrl) {
      return;
    }

    let count = Number.parseInt(this.localStorage.getItem('chunkLoadErrorReloadCount')!);

    if (count > 1) {
      // count > 1 means previous recovery didn't work out
      this.clearChunkLoadingErrorData();

      return;
    }

    count++;
    this.localStorage.setItem('chunkLoadErrorReloadCount', count.toString());
    this.router.navigateByUrl(recoverUrl).then((isSuccessful) => {
      if (isSuccessful) {
        this.clearChunkLoadingErrorData();
      }
    });
  }

  private clearChunkLoadingErrorData(): void {
    this.localStorage.removeItem('chunkLoadErrorReloadTarget');
    this.localStorage.removeItem('chunkLoadErrorReloadCount');
  }
}
